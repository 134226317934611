// module imports
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useLazyQuery } from '@apollo/client'
import { Link } from 'gatsby'
import moment from 'moment'

// local imports
import { GET_CLASS_STUDENT, GET_CLASS_SESSION_ACCESS } from 'utils/graphql'

// style imports
import {
  IndividualPurchaseContainer,
  IndividualPurchaseContents,
} from 'components/styleComponents/AccountStyles'
import { toast } from 'react-toastify'

const IndividualPurchase = ({ purchase }) => {
  const { t, i18n } = useTranslation('accountPage')
  const { loading: getClassLoading, data: getClassData } = useQuery(
    GET_CLASS_STUDENT,
    {
      variables: {
        input: {
          id: purchase.classId,
        },
      },
    },
  )

  const [getClassSessionAccess, { data: getClassAccessData }] = useLazyQuery(
    GET_CLASS_SESSION_ACCESS,
  )

  const accessClass = async () => {
    //check the class dates
    const todaysDate = Date.now() / 1000

    if (todaysDate < moment(getClassData?.getClass?.class?.startDate).unix()) {
      return toast.error(t('tooEarlyToJoinMessage'))
    }

    if (todaysDate > moment(getClassData?.getClass?.class?.endDate).unix()) {
      return toast.error(t('tooLateToJoinMessage'))
    }

    try {
      getClassSessionAccess({
        variables: {
          input: {
            id: getClassData.getClass.class.id,
          },
        },
      })
    } catch (err) {
      console.log(err)
    }
  }

  // redirect to the 3dmeet session when the user clicks on the "join class" button
  useEffect(() => {
    if (
      getClassAccessData?.getClassSessionAccess?.access?.redirectUrl &&
      typeof window !== 'undefined'
    ) {
      window.location.assign(
        getClassAccessData.getClassSessionAccess.access.redirectUrl,
      )
    }
  }, [getClassAccessData])

  // update the moment locale so dates are localized
  const [locStartDate, setLocStartDate] = useState(
    moment(getClassData?.getClass?.class?.startDate).format('MMMM Do YYYY'),
  )

  useEffect(() => {
    moment.locale(i18n.language)

    if (getClassData) {
      setLocStartDate(
        moment(getClassData?.getClass?.class?.startDate).format('MMMM Do YYYY'),
      )
    }
  }, [i18n.language, getClassData])

  const individualCardContents = <div>{t('individualClassCardMessage')}</div>
  const allOtherCardContents = (
    <div>
      {t('classStartDateLabel')}
      {/* Show "loading" while getting data, the start date if they've already selected a class,  a button to pick a class if they haven't yet. */}
      {getClassLoading ? (
        <p>{t('loading')}</p>
      ) : purchase.classId ? (
        <div>
          {locStartDate}
          <br />
          <button onClick={accessClass}>{t('classJoinButton')}</button>
        </div>
      ) : (
        <Link
          to="/select-your-class"
          state={{
            purchase,
          }}
        >
          {t('notApplicable')}
          <br />
          <button>{t('classSelectButton')}</button>
        </Link>
      )}
    </div>
  )

  return (
    <IndividualPurchaseContainer>
      <IndividualPurchaseContents>
        <h4>{purchase.priceName}</h4>
        <p>
          {t('classStatusLabel')}
          {purchase.status}
        </p>
        {purchase?.priceName?.toLowerCase().includes('individual')
          ? individualCardContents
          : allOtherCardContents}
      </IndividualPurchaseContents>
    </IndividualPurchaseContainer>
  )
}

export default IndividualPurchase
