// Module imports
import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

// local imports
// eslint-disable-next-line react/jsx-pascal-case
import Seo from 'components/General/SEO'
import Layout from 'components/Layout'
import UserContext from 'context/user/UserContext'
import { useAuth0 } from 'utils/react-auth0-spa'
import IndividualPurchase from 'components/AccountPage/IndividualPurchase'

// style imports
import {
  AccountContainer,
  AccountContents,
  AccountMenuContainer,
  AccountMenuOptions,
  AccountMenuOption,
  AccountLogoutButton,
  AccountMainViewContainer,
  AccountViewAccountDetailsContainer,
  AccountViewAccountContainer,
  AccountViewClassesDetailsContainer,
  AccountViewClassesFlexBox,
} from 'components/styleComponents/AccountStyles'

const Account = () => {
  //app contexts/global state
  const { isAuthenticated, auth0Loading, logout } = useAuth0()
  const {
    fetchUserFromFirestore,
    firestoreUserObject,
    updateUserName,
    updateEmail,
    userStateLoading,
  } = useContext(UserContext)

  useEffect(() => {
    //get the firestore user object on page load
    if (isAuthenticated) fetchUserFromFirestore()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  const { t } = useTranslation('accountPage')

  // LOCAL STATE VARIABLES
  const [selectedView, setSelectedView] = useState('classes')
  // local state for account details
  const [newUsername, setNewUsername] = useState('NA')
  const [newEmail, setNewEmail] = useState('NA')

  useEffect(() => {
    setNewUsername(firestoreUserObject?.username ?? 'NA')
    setNewEmail(firestoreUserObject?.email ?? 'NA')
  }, [firestoreUserObject])

  const clickUpdateUsername = async () => {
    if (newUsername === firestoreUserObject?.username) {
      toast.error(t('usernameToastError'))
    } else {
      await updateUserName(newUsername)
      toast.success(t('usernameSuccessMessage'))
    }
  }

  const clickUpdateEmail = async () => {
    if (newEmail === firestoreUserObject?.email) {
      toast.error(t('emailToastError'))
    } else {
      await updateEmail(newEmail)
      toast.success(t('emailSuccessMessage'))
    }
  }

  const accountView = (
    <AccountViewAccountDetailsContainer>
      <AccountViewAccountContainer>
        <h3>{t('accountDetilsHeader')}</h3>
        <p>{t('accountDetailsSubHeader')}</p>
        <br />
        <label htmlFor="username">
          {t('showUsernameLabel')}
          {newUsername}
        </label>
        {userStateLoading ? (
          <p>{t('processing')}</p>
        ) : (
          <div>
            {t('updateUsername')}
            <input onChange={(ev) => setNewUsername(ev.target.value)}></input>
            <button onClick={clickUpdateUsername}>{t('updateButton')}</button>
          </div>
        )}
        <br />
        <br />
        <label htmlFor="email">
          {t('showEmailLabel')} <br />
          {newEmail}
        </label>
        {userStateLoading ? (
          <p>{t('processing')}</p>
        ) : (
          <div>
            {t('updateEmail')}
            <input onChange={(ev) => setNewEmail(ev.target.value)}></input>
            <button onClick={clickUpdateEmail}>{t('updateButton')}</button>
          </div>
        )}
        <br />
      </AccountViewAccountContainer>
    </AccountViewAccountDetailsContainer>
  )

  const classView = (
    <AccountViewClassesDetailsContainer>
      <h3>{t('yourClassesHeader')}</h3>
      <AccountViewClassesFlexBox>
        {firestoreUserObject?.fwaPurchases ? (
          firestoreUserObject.fwaPurchases.map((purchase, index) => {
            return <IndividualPurchase purchase={purchase} key={index} />
          })
        ) : (
          <p>{t('noClassesMessage')}</p>
        )}
      </AccountViewClassesFlexBox>
    </AccountViewClassesDetailsContainer>
  )

  const mainView = (
    <AccountContents>
      <AccountMenuContainer>
        <AccountMenuOptions>
          <AccountMenuOption onClick={() => setSelectedView('account')}>
            {t('accountDetailsNav')}
          </AccountMenuOption>
          <AccountMenuOption onClick={() => setSelectedView('classes')}>
            {t('yourClassesNav')}
          </AccountMenuOption>
        </AccountMenuOptions>
        <AccountLogoutButton onClick={logout}>
          {t('signOutNav')}
        </AccountLogoutButton>
      </AccountMenuContainer>

      <AccountMainViewContainer>
        {selectedView === 'account' ? accountView : classView}
      </AccountMainViewContainer>
    </AccountContents>
  )

  return (
    <Layout showPipedrive={false}>
      <Seo title={t('seoTitle')} description={t('seoDescription')} />
      <AccountContainer>
        {auth0Loading ? (
          <p>{t('loading')}</p>
        ) : !isAuthenticated && !auth0Loading ? (
          <p>{t('notLoggedIn')}</p>
        ) : (
          mainView
        )}
      </AccountContainer>
    </Layout>
  )
}

export default Account
