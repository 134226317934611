import styled from '@emotion/styled'

export const AccountContainer = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
`

export const AccountContents = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const AccountMenuContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-top: 1px black solid;
  border-bottom: 1px black solid;

  @media (max-width: 1000px) {
    font-size: 0.8em;
  }
`

export const AccountMenuOptions = styled.div`
  display: flex;
  justify-content: center;
`

export const AccountMenuOption = styled.div`
  margin-left: 15px;
  cursor: pointer;
`

export const AccountLogoutButton = styled.div`
  background-color: #fd7603;
  border: none;
  padding: 5px 25px;
  cursor: pointer;
`

export const AccountMainViewContainer = styled.div`
  width: 100%;
`

export const AccountViewAccountDetailsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const AccountViewAccountContainer = styled.div`
  width: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  @media (max-width: 700px) {
    width: 100%;
  }
`

export const AccountViewClassesDetailsContainer = styled.div`
  width: 100%;
`

export const AccountViewClassesFlexBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`

export const IndividualPurchaseContainer = styled.div`
  width: 300px;
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const IndividualPurchaseContents = styled.div`
  width: 80%;
  background-color: rgba(110, 108, 103, 0.3);
  padding: 10px;
`
